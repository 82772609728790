import React, {useReducer} from 'react';
import { Route, HashRouter, Routes } from 'react-router-dom';

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

// Auth Provider
import { AuthProvider } from './context/AuthContext';

 // Context and Reducers
 import { CompanyContext } from './context/CompanyContext';
 import { CountryContext } from './context/CountryContext';
 import { CompanyReducer } from './reducers/CompanyReducer';
 import { CountryReducer } from './reducers/CountryReducer';

// Client
import ManageAnnouncements from './client/announcement/ManageAnnouncements';
import ManageCompanyJobLevelMappings from './client/job-level/ManageCompanyJobLevelMappings';
import ManageCompanyJobLevels from './client/job-level/ManageCompanyJobLevels';
import JobMapping from './client/job-position/JobMapping';
import JobPositionToFieldOfStudyMapping from './client/job-position/JobPositionToFieldOfStudyMapping';
import ManageJobPositions from './client/job-position/ManageJobPositions';
import ManageCompanyRequirements from './client/requirement/ManageCompanyRequirements';
import OrderPermits from './client/work-permit/OrderPermits';

// Country
import VisaCitizenshipProcessingTime from './country/citizenship-processing-time/VisaCitizenshipProcessingTime';
// import VisaGovernmentProcessing from './country/government-processing-time/VisaGovernmentProcessing';
import VisaGovernmentProcessingTime from './country/government-processing-time/VisaGovernmentProcessingTime';

import CountryJobLevel from './country/job-level/CountryJobLevel';
import CountryJobLevelForm from './country/job-level/CountryJobLevelForm';
import WorkPermitOutput from './country/recruiter-output/WorkPermitOutput';
import RequirementForm from './country/requirement/RequirementForm';
import RequirementsList from './country/requirement/RequirementsList';
import AddWorkPermit from './country/work-permit/AddWorkPermit';
import WorkPermitsList from './country/work-permit/WorkPermitsList';

// General
import SelectCompany from './general/country-client-select/SelectCompany';
import SelectCountry from './general/country-client-select/SelectCountry';
import FieldsOfStudyForm from './general/field-of-study/FieldsOfStudyForm';
import ManageFieldsOfStudy from './general/field-of-study/ManageFieldsOfStudy';
import LandingPage from './general/landing-page/LandingPage';
import ManageClients from './general/manage-clients/ManageClients';
import EditCountry from './general/manage-countries/EditCountry';
import ManageCountries from './general/manage-countries/ManageCountries';
import LoginPage from './general/session/LoginPage';
import LoginHandler from './general/session/LoginHandler';
import SessionManager from './general/session/SessionManager';
import AnnouncementForm from './client/announcement/AnnouncementForm';

//MSAL
import { MsalProvider } from '@azure/msal-react';

// Protected Routes
import ProtectedRoute from './services/ProtectedRoute';

const App = ({msalInstance}) => {
  const initialCompanyState = {
    companies: [],
    selectedCompany: null,
  };

  const initialCountryState = {
    countries: [],
    selectedCountry: null,
  };

  const companyReducer = (state, action) => CompanyReducer(state, action);
  const countryReducer = (state, action) => CountryReducer(state, action);

  const [companyState, companyDispatch] = useReducer(companyReducer, initialCompanyState);
  const [countryState, countryDispatch] = useReducer(countryReducer, initialCountryState);

  
  return (
    <MsalProvider instance={msalInstance}>
    <AuthProvider>
    <CountryContext.Provider value={[countryState, countryDispatch]}>
      <CompanyContext.Provider value={[companyState, companyDispatch]}>
          <SessionManager />
          <HashRouter basename="/">
          <Routes>
              <Route exact path="/" element={<LoginHandler />} />
              <Route exact path="/login" element={<LoginPage />} />
              
              {/* Protected Routes */}
              <Route element={ <ProtectedRoute/> } >
                <Route exact path="/home" element={<LandingPage />} />
                {/* Management Routes */}
                <Route exact path="/clients" element={<ManageClients />} />
                <Route exact path="/countries" element={<ManageCountries />} />
                <Route exact path="/countries/:id/edit" element={<EditCountry />} />
                <Route exact path="/countries/create" element={<EditCountry />} />
                <Route exact path="/manage/fields-of-study" element={<ManageFieldsOfStudy />} />
                <Route exact path="/manage/fields-of-study/create" element={<FieldsOfStudyForm />} />
                <Route exact path="/manage/fields-of-study/:id/edit" element={<FieldsOfStudyForm />} />

                {/* Client Configuration Routes */}
                <Route exact path="/select-company" element={<SelectCompany />} />
                <Route exact path="/companies/:id/requirements" element={<ManageCompanyRequirements />} />
                <Route exact path="/companies/:id/job-levels/" element={<ManageCompanyJobLevels />} />
                <Route exact path="/companies/:id/job-level-mappings/" element={<ManageCompanyJobLevelMappings />} />
                <Route exact path="/companies/:id/job-positions/create" element={<ManageJobPositions />} />
                <Route exact path="/companies/:id/job-positions/positions-to-job-codes" element={<JobMapping />} />
                <Route exact path="/companies/:id/job-positions/positions-to-fields-of-study" element={<JobPositionToFieldOfStudyMapping />} />
                <Route exact path="/companies/:id/order-permits" element={<OrderPermits />} />
                <Route exact path="/companies/:id/announcements" element={<ManageAnnouncements />} />
                <Route exact path="/companies/:id/announcements/create" element={<AnnouncementForm />} />
                <Route exact path="/companies/:id/announcements/:announcementId/edit" element={<AnnouncementForm />} />

                {/* Immigration Law Configuration Routes */}
                <Route exact path="/select-country" element={<SelectCountry />} />
                <Route exact path="/countries/:countryId/citizenship-processing-times" element={<VisaCitizenshipProcessingTime />} />
                <Route exact path="/countries/:countryId/job-levels" element={<CountryJobLevel />} />
                <Route exact path="/countries/:countryId/job-levels/create" element={<CountryJobLevelForm />} />
                <Route exact path="/countries/:countryId/job-levels/:id/edit" element={<CountryJobLevelForm />} />
                <Route exact path="/countries/:countryId/government-processing-times" element={<VisaGovernmentProcessingTime />} />
                <Route exact path="/countries/:countryId/output" element={<WorkPermitOutput />} />
                <Route exact path="/countries/:countryId/requirements" element={<RequirementsList />} />
                <Route exact path="/countries/:countryId/requirements/create" element={<RequirementForm />} />
                <Route exact path="/countries/:countryId/requirements/:id/edit" element={<RequirementForm />} />
                <Route exact path="/countries/:countryId/work-permits" element={<WorkPermitsList />} />
                <Route exact path="/countries/:countryId/work-permits/create" element={<AddWorkPermit />} />
                <Route exact path="/countries/:countryId/work-permits/:id/edit" element={<AddWorkPermit />} />
              </Route>

              {/* Authentication Redirect */}
              <Route exact path="/id_token=/*" element={<LoginPage />} />
            </Routes>
          </HashRouter>
      </CompanyContext.Provider>
    </CountryContext.Provider>
    </AuthProvider>
    </MsalProvider>
  );
}

export default App;
