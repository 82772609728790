import {useEffect} from 'react';
import { useIsAuthenticated } from "@azure/msal-react";
import { useAuth } from '../../context/AuthContext';
import { useNavigate , useLocation} from 'react-router-dom';
import Loading from '../components/Loading';

const LoginHandler = () => {
  const isAuthenticated = useIsAuthenticated();
  const { isUserLoggedIn, checkUserStatus } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { message } = location.state || {};

  const checkAuthentication = async () => {
    if (isAuthenticated) {
      if (isUserLoggedIn) {
        navigate('/home', { replace: true });
      } else {
        const valid = await checkUserStatus();
        if (valid && !message) {
          console.log('redirect to home');
          navigate('/home', { replace: true });
        }
        else if (valid && message) {
          console.log('redirect to the location');
          navigate(`${message}`, { replace: true });
        }
      }
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    checkAuthentication();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
  

  return (<Loading />);
};

export default LoginHandler;
